import { render, staticRenderFns } from "./EditProductFeaturesModal.vue?vue&type=template&id=58a00d5d&scoped=true&"
import script from "./EditProductFeaturesModal.vue?vue&type=script&lang=js&"
export * from "./EditProductFeaturesModal.vue?vue&type=script&lang=js&"
import style0 from "./EditProductFeaturesModal.vue?vue&type=style&index=0&id=58a00d5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a00d5d",
  null
  
)

export default component.exports