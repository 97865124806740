<template>
  <div>
    <b-modal
        id="create-category"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Adaugare categorie</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="category.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Descriere</label>
              <b-form-textarea v-model="category.description" size="sm" class="form-control"></b-form-textarea>
            </div>
            <div class="form-group">
              <label>Status</label>
              <b-form-select v-model="category.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6">
            <b-button variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Categories from '@/components/restaurantManagerEndpoints/categories'
import { bus } from "../../../main";

export default {
  data() {
    return {
      files: [],
      edit: false,
      showModal: false,
      category: {
        name: '',
        description: null,
        is_active: true
      },
      statuses: [
        { value: true, text: 'Activa' },
        { value: false, text: 'Inactiva' },
      ],
    }
  },
  components: {

  },
  created() {
    this.$root.$refs.RestaurantManagerCreateCategoryModal = this;
  },
  methods: {
    cancel() {
      this.showModal = false
      this.category = {
        name: '',
        description: '',
        is_active: true
      }
    },
    save() {
      Categories.store(this.category).then(response => {
        bus.$emit('categoryCreated', response.data.data)
        this.cancel()
      })
    }
  }
}
</script>
