<template>
  <div class="main">
    <CreateCategoryModal ref="CreateCategoryModal" />
    <EditCategoryModal ref="EditCategoryModal" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga o categorie
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="categories.length > 0">
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="width: 90px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>ORDER</b-th>
              <b-th>DENUMIRE</b-th>
              <b-th>STATUS</b-th>
            </b-tr>
          </b-thead>
          <draggable
              v-model="categories"
              tag="tbody"
              handle=".handle"
              ghost-class="ghost"
              @end="changePositions"
          >
            <tr v-for="category in categories" :key="category.id">
                <b-td>
                  <b-link class="handle mr-2" style="cursor: move">
                    <i class="mdi mdi-sort"
                       style="font-size: 24px; color: #A1A7AF;">
                    </i>
                  </b-link>
                  <b-link
                      @click="editCategory(category.id)"
                  >
                    <i class="mdi mdi-pencil"
                       style="font-size: 24px; color: #A1A7AF;">
                    </i>
                  </b-link>
                </b-td>
                <b-td>{{category.id}}</b-td>
                <b-td>{{category.order_index}}</b-td>
                <b-td>{{category.name}}</b-td>
                <b-td>
                  <b-alert class="p-1 text-center" variant="success" show v-if="category.is_active">
                    ACTIVATA
                  </b-alert>
                  <b-alert class="p-1 text-center" variant="danger" show v-else>
                    DEZACTIVATA
                  </b-alert>
                </b-td>
              </tr>
          </draggable>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="categories.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert show variant="info">Nu exista categorii</b-alert>
    </b-row>
  </div>
</template>

<script>
import RestaurantManagerCategories from '@/components/restaurantManagerEndpoints/categories'
import CreateCategoryModal from "./CreateCategoryModal";
import EditCategoryModal from "./EditCategoryModal";
import {bus} from "../../../main";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      error: false,
      categories: [],
      paging: {
        page: 1,
        per_page: 100,
        total: 100
      },
      drag: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 250,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  components: {
    CreateCategoryModal,
    EditCategoryModal,
    draggable
  },
  methods: {
    changePositions(data) {
      this.categories.forEach((category, index) => {
        this.categories[index].order_index = index;
        RestaurantManagerCategories.update(category.id, category).then(() => {

        })
      })
    },
    editCategory(id) {
      bus.$emit('showCategoryEditModal', id)
    },
    showModal() {
      this.$root.$refs.RestaurantManagerCreateCategoryModal.showModal = true
    },
    getCategories() {
      const filters = {
        status: [3,4,5]
      }
      RestaurantManagerCategories.getAll(this.paging, filters).then(response => {
        this.categories = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getCategories()
    }
  },
  mounted() {
    bus.$on('categoryCreated', () => {
      this.getCategories()
    })
    bus.$on('categoryUpdated', () => {
      this.getCategories()
    })
    this.getCategories()
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #1bc5bd;
}
</style>