<template>
  <div>
    <b-modal
        id="create-restaurant"
        hide-header
        hide-footer
        v-model="showModalFeatures"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Modificare caracteristica produs</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="feature.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Label</label>
              <b-input type="text" v-model="feature.label" size="sm" class="form-control"></b-input>
            </div>
            <div class="form-group">
              <label>Obligatoriu</label>
              <b-form-select v-model="feature.required" :options="yesOrNo" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Minim optiuni</label>
                  <b-input type="number" v-model="feature.min" placeholder="1"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Maxim optiuni</label>
                  <b-input type="number" v-model="feature.max" placeholder="1"></b-input>
                </div>
              </div>
            </div>
        
        <!-- Optiuni -->
            <div class="row">
              <div v-for="(option, ind) in feature.options" :key="ind" class="option-card">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Nume</label>
                      <b-input type="text" v-model="option.name" placeholder="Nume"></b-input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Label</label>
                      <b-input type="text" v-model="option.label" placeholder="Label"></b-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Pret</label>
                      <b-input type="number" v-model="option.price" placeholder="1"></b-input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Selectata</label>
                      <b-form-select v-model="option.checked" :options="yesOrNo" size="sm" class="form-control"></b-form-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Canitate initiala</label>
                      <b-input type="number" v-model="option.qty" placeholder="1"></b-input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Canitate maxima</label>
                      <b-input type="number" v-model="option.max" placeholder="1"></b-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <b-button @click="removeOption(ind)" variant="danger">Sterge Optiunea</b-button>
                </div>
              </div>
            </div>

            <!-- end optiuni -->

            <b-button @click="addNewOption" variant="primary">Adauga Optiune</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Se salveaza...</b-button>
          </b-col>
          <b-col md="6" class="p-1 mt-5">
            <b-button variant="danger" @click="deleteProductFeature" style="width: 100%;">STERGE CARACTERISTICA</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductsFeatures from '@/components/restaurantManagerEndpoints/productsFeatures'
import { bus } from "../../../main";

export default {
  data() {
    return {
      isBusy: false,
      showModalFeatures: false,
      feature: {
        name: '',
        label: '',
        min: 0,
        max: 0,
        required: false,
        options: [],
      },
      option: {
        name: '',
        label: '',
        price: 1,
        qty: 1,
        max: 1,
        multiple: false,
        checked: false,
      },
      yesOrNo: [
        { value: true, text: 'Da' },
        { value: false, text: 'Nu' },
      ],
    }
  },
  methods: {
    addNewOption() {
      let newOption = JSON.parse(JSON.stringify(this.option));
      this.feature.options.push(newOption);
    },
    removeOption(ind) {
      this.feature.options.splice(ind, 1);
    },
    cancel() {
      this.option = {
        name: '',
        label: '',
        price: 1,
        qty: 1,
        max: 1,
        multiple: false,
        checked: false,
      }
      this.showModalFeatures = false
      this.isBusy = false
    },
    save() {
      this.isBusy = true;

      if (this.feature.options.length) {
        for (let i = 0; i < this.feature.options.length; i++) {
          if (this.feature.options[i].max > 1) {
            this.feature.options[i].multiple = true;
          } else {
            this.feature.options[i].multiple = false;
          }
        }
      }

      ProductsFeatures.update(this.feature.id, this.feature).then(response => {
        bus.$emit('productFeaturesUpdated', response.data.data)
        this.cancel()
      })
    },
    deleteProductFeature() {
      this.$swal({
        title: 'Doresti sa stergi?',
        text: 'Va fi stearsa ireversibil',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'DA, STERG',
        cancelButtonText: 'NU, RENUNTA',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ProductsFeatures.delete(this.feature.id).then(() => {
            bus.$emit('productFeaturesRemoved')
            this.cancel()
          }).catch(error => {
            this.$toastr.e(error.response.data.message);
          })
        }
      })
    }
  },
  mounted() {
    bus.$on('showRestaurantProductFeaturesEditModal', id => {
      ProductsFeatures.get(id).then(response => {
        this.feature = response.data.data
        this.showModalFeatures = true
      })
    })
  },
}
</script>

<style scoped>
.option-card {
  background: #ccc;
  padding: 10px 15px;
  margin-bottom: 10px; 
}
.option-card:nth-child(even) {
  border-top: 1px solid seagreen;
  border-bottom: 1px solid seagreen;
}
</style>