<template>
  <div class="main">
    <b-row v-if="orders.length > 0">
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="6" v-for="(orderData, index) in orders" :key="index">
        <div class="box_order_form">
          <div class="head text-center" :class="orderStatusesBackgroundColors[orderData.status]">
            <h3>Comanda #{{ orderData.id }} ({{ formatDateTime(orderData.created_at) }})</h3>
            <h3>Status: {{ orderStatus(orderData.status) }}</h3>
            <b-alert class="p-1 text-center mt-2" v-if="orderData.preorder_date" show variant="danger"><strong>Precomanda la:</strong> {{orderData.preorder_date}}</b-alert>
            <b-alert class="p-1 text-center" v-if="orderData.paid_with_card" show variant="success">ACHITATA CU CARD ONLINE</b-alert>
            <b-alert class="p-1 text-center" v-else show variant="primary">SE VA ACHITA CASH</b-alert>
            <h3 v-if="orderData.status === 1">Gata de livrare la: {{ addMinutesToDate(orderData.created_at, orderData.est_restaurant_time_min) }} </h3>
            <b-col md="12" v-if="orderData.driver_id !== null">
              <div class="detail_page_head pt-4 clearfix text-center">
                <h5>DATA ESTIMATA LA CARE AJUNGE LIVRATORUL:</h5>
                <h5> {{ addMinutesToDate(orderData.created_at, orderData.est_driver_time_to_restaurant_min) }}</h5>
              </div>
            </b-col>
            <b-row v-if="orderData.status === 1">
              <b-col md="12">
                <h5>Adauga timp</h5>
              </b-col>
              <b-col md="12" class="p-2">
                <b-button @click="addTime(orderData, 10)" style="width: 100%;" variant="primary">+10 Minute</b-button>
              </b-col>
              <b-col md="12" class="p-2">
                <b-button @click="addTime(orderData, 20)" style="width: 100%;" variant="primary">+20 Minute</b-button>
              </b-col>
              <b-col md="12" class="p-2">
                <b-button @click="addTime(orderData, 30)" style="width: 100%;" variant="primary">+30 Minute</b-button>
              </b-col>
            </b-row>
          </div>
          <!-- /head -->
          <div class="main">
            <b-row>
              <b-col md="12">
                <h4>Produse comandate</h4>
                <ProductsDetails
                      :products=orderData.products 
                    />
              </b-col>
              <b-col md="12">
                <b-row class="text-center">
                  <b-col cols="6">
                    <h5>Detalii livrare</h5>
                    <div class="detail_page_head pt-4 clearfix">
                      <h6>{{ orderData.order_details.firstName }} {{ orderData.order_details.lastName }}</h6>
                      <span>{{ orderData.order_details.street }} {{ orderData.order_details.address_extra }}, {{ orderData.order_details.zip }} {{ orderData.order_details.city }}</span>
                      <p>{{ orderData.order_details.phone }}</p>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <h4>Detalii plata</h4>
                    <div class="detail_page_head pt-4 clearfix">
                      <h6 v-if="!orderData.voucher">Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax) }}</h6>
                      <h6 v-if="orderData.voucher">
                        Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax - calculateVoucherAmount(orderData)) }}
                      </h6>
                      <span>Produse: {{ toCurrency(orderData.order_total) }}</span>
                      <p class="mb-0">Livrare: {{ toCurrency(orderData.delivery_tax) }}</p>
                      <p v-if="orderData.voucher && orderData.voucher.value_type === 'fixed'" class="text-danger">
                        Cupon reducere: -{{ toCurrency(orderData.voucher.value) }}
                      </p>
                      <p v-if="orderData.voucher && orderData.voucher.value_type === 'percent'" class="text-danger">
                        Cupon reducere: -{{ toCurrency(calculateVoucherAmount(orderData)) }} ({{ orderData.voucher.value }} %)
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12" v-if="orderData.order_details.customer_notes">
                    <h4>Mentiuni comanda</h4>
                    <div class="detail_page_head clearfix">
                      <p>{{ orderData.order_details.customer_notes }} </p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" class="p-2">
                <b-button style="width: 100%" variant="danger" @click="changeOrderStatus(orderData, 5)">ANULEAZA COMANDA</b-button>
              </b-col>
              <b-col md="6" class="p-2">
                <b-button style="width: 100%" v-if="orderData.status === 0" variant="warning" @click="changeOrderStatus(orderData, 1)">ACCEPTA COMANDA</b-button>
                <b-button style="width: 100%" v-if="orderData.status === 1" variant="success" @click="changeOrderStatus(orderData, 2)">COMANDA ESTE PREGATITA</b-button>
                <b-button style="width: 100%" v-if="orderData.status === 2 && orderData.driver_id" variant="info" @click="changeOrderStatus(orderData, 3)">PREDA COMANDA LIVRATORULUI</b-button>
                <span v-if="orderData.status === 2 && !orderData.driver_id">
                  <b-alert style="width: 100%" show variant="info">Se astepta confirmarea livratorul...</b-alert>
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert show variant="info">Nu exista precomenzi</b-alert>
    </b-row>
  </div>
</template>

<script>
import ProductsDetails from '@/components/shared/productsDetails'
import RestaurantManagerOrders from '@/components/restaurantManagerEndpoints/orders'
import { bus } from "../../../main";
export default {
  name: 'precomenzi',
  data() {
    return {
      error: false,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      }
    }
  },
  components: {
    ProductsDetails,
  },
  methods: {
    emitToParent (type, total) {
      this.$emit('childToParent', type, total);
    },
    getOrders() {
      const filters = {
        status: [0,1,2],
        preorder_date: 'preorder'
      }
      RestaurantManagerOrders.getAll(this.paging, filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
        this.emitToParent('preOrdersTotal', this.paging.total);
      })
      const filtersNewOrders = {
        status: [0,1,2],
        preorder_date: 'order'
      }
      this.paging.per_page = 99;
      RestaurantManagerOrders.getAll(this.paging, filtersNewOrders).then(response => {
        this.emitToParent('newOrdersTotal', response.data.meta.total);
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    },
    async changeOrderStatus(order, newStatus) {
      const updateData = {
        status: newStatus,
      }
      if (newStatus === 1) {
        const {value: timp} = await this.$swal.fire({
          title: 'In cat timp va fi gata de livrare?',
          input: 'select',
          confirmButtonText: 'ACCEPT COMANDA',
          cancelButtonText: 'NU, RENUNTA',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          inputOptions: {
            '5': '5 Minute',
            '10': '10 Minute',
            '15': '15 Minute',
            '20': '20 Minute',
            '30': '30 Minute',
            '40': '40 Minute',
            '60': '1 ORA',
            '90': '1 ORA si 30 MIN',
            '120': '2 ORE',
          },
          inputValue: '30',
          inputPlaceholder: '',
          showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              resolve()
            })
          }
        })
        if (!timp) {
          return
        }
        updateData.est_restaurant_time_min = parseInt(timp)
      }
      if (newStatus === 5) {
        this.$swal({
          title: 'Doresti sa anulezi comanda?',
          text: 'Comanda va fi anulata. O poti revedea in istoric comenzi',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'DA, ANULEAZA COMANDA',
          cancelButtonText: 'NU, RENUNTA',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            RestaurantManagerOrders.update(order.id, updateData).then(() => {
              this.getOrders()
            }).catch(error => {
              this.$toastr.e(error.response.data.message);
            })
          }
        })
        return
      }
      RestaurantManagerOrders.update(order.id, updateData).then(() => {
        this.getOrders()
      }).catch(error => {
        this.$toastr.e(error.response.data.message);
      })
    },
    addTime(order, minutes) {
      const updateData = {
        est_restaurant_time_min: minutes
      }
      RestaurantManagerOrders.update(order.id, updateData).then(() => {
        this.getOrders()
      }).catch(error => {
        this.$toastr.e(error.response.data.message);
      })
    }
  },
  mounted() {
    bus.$on('restaurant_new_order', order => {
      this.getOrders()
    })
    bus.$on('restaurant_update_order', order => {
      this.getOrders()
    })
    bus.$on("driver_update_order", order => {
      this.getOrders()
    })
    this.getOrders()
  }
}
</script>