<template>
  <div class="main">
    <CreateProductModal ref="CreateProductModal" />
    <EditProductModal ref="EditProductModal" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga un produs
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <div class="form-group">
          <label>Categorie</label>
          <b-form-select @input="getProducts" v-model="filters.category_id" :options="categoriesOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-input @keyup="getProducts" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
    </b-row>
    <b-row v-if="products.length > 0">
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="width: 110px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>ORDER</b-th>
              <b-th>IMAGINE</b-th>
              <b-th>NUME</b-th>
              <b-th>DESCRIERE</b-th>
              <b-th>CATEGORIE</b-th>
              <b-th>PRET</b-th>
              <b-th>STATUS</b-th>
            </b-tr>
          </b-thead>
          <draggable
              v-model="products"
              tag="tbody"
              handle=".handle"
              ghost-class="ghost"
              @end="changePositions"
          >
            <b-tr v-for="product of products" :key="product.id">
              <b-td>
                <b-link class="handle mr-2" style="cursor: move">
                  <i class="mdi mdi-sort"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    @click="editProduct(product.id)"
                >
                  <i class="mdi mdi-pencil"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{product.id}}</b-td>
              <b-td>{{product.order_index}}</b-td>
              <b-td>
                <b-img v-if="product.image_url !== ''" :src="product.image_url" width="50"></b-img>
              </b-td>
              <b-td style="min-width: 200px;">{{product.name}}</b-td>
              <b-td>{{product.description}}</b-td>
              <b-td><span v-if="product.category">{{product.category.name }}</span></b-td>
              <b-td>{{ toCurrency(product.price) }}</b-td>
              <b-td>
                <b-alert class="p-1 text-center" variant="success" show v-if="product.is_active">
                  ACTIVAT
                </b-alert>
                <b-alert class="p-1 text-center" variant="danger" show v-else>
                  DEZACTIVAT
                </b-alert>
              </b-td>
            </b-tr>
          </draggable>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="products.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert variant="info" show>Nu exista produse</b-alert>
    </b-row>
  </div>
</template>

<script>
import RestaurantManagerProducts from '@/components/restaurantManagerEndpoints/products'
import RestaurantManagerCategories from '@/components/restaurantManagerEndpoints/categories'
import CreateProductModal from "./CreateProductModal";
import EditProductModal from "./EditProductModal"
import {bus} from "../../../main";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      error: false,
      products: [],
      categories: [],
      paging: {
        page: 1,
        per_page: 50,
        total: 10,
        search: ''
      },
      filters: {
        category_id: ''
      }
    }
  },
  computed: {
    categoriesOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE CATEGORIILE',
      }
      returnData.push(tmpObj)
      this.categories.forEach(category => {
        const tmpObj = {
          value: category.id,
          text: category.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
  },
  components: {
    CreateProductModal,
    EditProductModal,
    draggable
  },
  methods: {
    changePositions(data) {
      this.products.forEach((product, index) => {
        this.products[index].order_index = index;
        RestaurantManagerProducts.update(product.id, product).then(() => {

        })
      })
    },
    editProduct(id) {
      bus.$emit('showRestaurantProductEditModal', id)
    },
    showModal() {
      this.$root.$refs.RestaurantManagerCreateProductModal.showModal = true
    },
    getProducts() {
      RestaurantManagerProducts.getAll(this.paging, this.filters).then(response => {
        this.products = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    getCategories() {
      const paging = {
        page: 1,
        per_page: 100,
        total: 10
      }
      const filters = {}
      RestaurantManagerCategories.getAll(paging, filters).then(response => {
        this.categories = response.data.data
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getProducts()
    }
  },
  mounted() {
    bus.$on('productCreated', () => {
      this.getProducts()
    })
    bus.$on('productUpdated', () => {
      this.getProducts()
    })
    bus.$on('productRemoved', () => {
      this.getProducts()
    })
    this.getProducts()
    this.getCategories()
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #1bc5bd;
}
</style>