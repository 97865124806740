import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Restaurant {
  get () {
    return axios.get(url + '/restaurant-manager/restaurant')
  }

  getDrivers () {
    return axios.get(url + '/restaurant-manager/restaurant/drivers')
  }

  update (id, data) {
    return axios.put(url + '/restaurant-manager/restaurant/' + id, data)
  }
}
export default new Restaurant()
