import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class ProductsFeatures {
  get (slug) {
    return axios.get(url + '/restaurant-manager/product_feature/' + slug)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        if(typeof filters[key] !== 'string') {
          options += '&filters[' + key + ']=[' + filters[key] + ']'
        } else {
          options += '&filters[' + key + ']=' + filters[key]
        }
      })
    }
    return axios.get(url + '/restaurant-manager/product_feature' + options)
  }

  store (data) {
    return axios.post(url + '/restaurant-manager/product_feature', data)
  }

  update (id, data) {
    return axios.put(url + '/restaurant-manager/product_feature/' + id, data)
  }

  delete (id) {
    return axios.delete(url + '/restaurant-manager/product_feature/' + id)
  }
}
export default new ProductsFeatures()
