<template>
  <div class="main">
    <CreateProductFeaturesModal ref="CreateProductFeaturesModal" />
    <EditProductFeaturesModal ref="EditProductFeaturesModal" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga o caracteristica noua
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-input @keyup="getProductsFeatures" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
    </b-row>
    <b-row v-if="productsFeatures.length > 0">
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 50px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>NUME</b-th>
              <b-th>LABEL (Vizibil de clienti)</b-th>
              <b-th>MIN (optiuni de ales)</b-th>
              <b-th>MAX (optiuni de ales)</b-th>
              <b-th>OBLIGATORIU</b-th>
            </b-tr>
          </b-thead>
          <b-tr v-for="feature of productsFeatures" :key="feature.id">
              <b-td>
                <b-link
                    @click="editProductFeature(feature.id)"
                >
                  <i class="mdi mdi-pencil"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{feature.id}}</b-td>
              <b-td>{{feature.name}}</b-td>
              <b-td>{{feature.label}}</b-td>
              <b-td>{{feature.min}}</b-td>
              <b-td>{{feature.max}}</b-td>
              <b-td>
                <span v-if="feature.required">DA</span>
                <span v-else>NU</span>
              </b-td>
            </b-tr>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="productsFeatures.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert variant="info" show>Nu exista caracteristici</b-alert>
    </b-row>
  </div>
</template>

<script>
import productsFeatures from '@/components/restaurantManagerEndpoints/productsFeatures'
import {bus} from "../../../main";
import CreateProductFeaturesModal from "./CreateProductFeaturesModal";
import EditProductFeaturesModal from "./EditProductFeaturesModal"

export default {
  data() {
    return {
      error: false,
      productsFeatures: [],
      paging: {
        page: 1,
        per_page: 50,
        total: 10,
        search: ''
      },
      filters: {}
    }
  },
  components: {
    CreateProductFeaturesModal,
    EditProductFeaturesModal
  },
  methods: {
    editProductFeature(id) {
      bus.$emit('showRestaurantProductFeaturesEditModal', id)
    },
    showModal() {
      this.$root.$refs.RestaurantManagerCreateProductFeatruesModal.showModalFeatures = true
    },
    getProductsFeatures() {
      productsFeatures.getAll(this.paging, this.filters).then(response => {
        this.productsFeatures = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getProductsFeatures()
    }
  },
  mounted() {
    bus.$on('productFeaturesCreated', () => {
      this.getProductsFeatures()
    })
    bus.$on('productFeaturesUpdated', () => {
      this.getProductsFeatures()
    })
    bus.$on('productFeaturesRemoved', () => {
      this.getProductsFeatures()
    })
    this.getProductsFeatures()
  }
}
</script>
