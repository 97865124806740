<template>
  <div>
    <b-modal
        id="create-restaurant"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Modificare produs</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Categorie</label>
              <b-form-select v-model="product.category_id" :options="categoriesOptions" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="product.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Descriere</label>
              <b-form-textarea v-model="product.description" size="sm" class="form-control"></b-form-textarea>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Pret</label>
                  <b-input type="number" v-model="product.price" placeholder="Pret (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status</label>
                  <b-form-select v-model="product.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Pozitie</label>
              <b-input type="text" v-model="product.order_index" placeholder="Pozitia in lista"></b-input>
            </div>
            <div class="form-group">
              <label>Imagine</label>
              <ImageUploadCropper
                  :aspectRatio="1"
                  :defaultImage="product.image_url"
                  @cropMode="edit = $event"
                  @fileUpdated="files = $event"
                  @fileRemoved="fileRemoved()"
                  :uploadButtonText="$t('media.uploadImage')"
                  :cropButtonText="$t('media.cropImage')"
                  :removeButtonText="$t('media.removeImage')"
              >
              </ImageUploadCropper>
            </div>
            <div class="form-group">
              <label>Caracteristici</label>
              <v-select
                  v-model="product.features_ids"
                  dir="ltr"
                  multiple
                  label="text"
                  :closeOnSelect="false"
                  :reduce="text => text.value"
                  :options="featureOptions"
              >
              </v-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Se salveaza...</b-button>
          </b-col>
          <b-col md="6" class="p-1 mt-5">
            <b-button variant="danger" @click="deleteProduct" style="width: 100%;">STERGE PRODUSUL</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Products from '@/components/restaurantManagerEndpoints/products'
import ProductsFeatures from '@/components/restaurantManagerEndpoints/productsFeatures'
import Categories from '@/components/restaurantManagerEndpoints/categories'
import ImageUploadCropper from "../../System/Media/ImageUploadCropper";
import { bus } from "../../../main";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

export default {
  data() {
    return {
      isBusy: false,
      files: [],
      showModal: false,
      product: {
        name: '',
        description: '',
        is_active: true,
        category_id: '',
        price: 0,
        features_ids: []
      },
      statuses: [
        { value: true, text: 'Activ' },
        { value: false, text: 'Inactiv' },
      ],
      categories: [],
      features: []
    }
  },
  components: {
    vSelect,
    ImageUploadCropper
  },
  methods: {
    fileRemoved() {
      this.files = []
      this.product.image_url = ""
    },
    getCategories() {
      const params = {
        page: 1,
        per_page: 100
      }
      Categories.getAll(params).then(response => {
        this.categories = response.data.data
      })
    },
    getFeatures() {
      const params = {
        page: 1,
        per_page: 100
      }
      ProductsFeatures.getAll(params).then(response => {
        this.features = response.data.data
      })
    },
    cancel() {
      this.files = [];
      this.showModal = false
      this.isBusy = false
    },
    save() {
      //Image not updated
      this.isBusy = true
      if(this.files.length === 0) {
        Products.update(this.product.id, this.product).then(response => {
          bus.$emit('productUpdated', response.data.data)
          this.cancel()
        })
        return
      }
      //Image updated
      const reader = new FileReader();
      reader.readAsDataURL(this.files[0].file);
      reader.onloadend = () => {
        this.product.new_image = reader.result
        Products.update(this.product.id, this.product).then(response => {
          bus.$emit('productUpdated', response.data.data)
          this.cancel()
        })
      }
    },
    deleteProduct() {
      this.$swal({
        title: 'Doresti sa stergi produsul?',
        text: 'Produsul va fi sters ireversibil',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'DA, STERG PRODUSUL',
        cancelButtonText: 'NU, RENUNTA',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Products.delete(this.product.id).then(() => {
            bus.$emit('productRemoved')
            this.cancel()
          }).catch(error => {
            this.$toastr.e(error.response.data.message);
          })
        }
      })
    }
  },
  mounted() {
    this.getCategories()
    this.getFeatures()
    bus.$on('showRestaurantProductEditModal', id => {
      Products.get(id).then(response => {
        this.product = response.data.data
        this.showModal = true
      })
    })
  },
  computed: {
    categoriesOptions() {
      const returnData = []
      this.categories.forEach(category => {
        const tmpObj = {
          value: category.id,
          text: category.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    featureOptions() {
      const returnData = [];
      this.features.forEach(feature => {
        const tmpObj = {
          value: feature.id,
          text: feature.label + ' - ' + feature.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
  },
}
</script>
