<template>
  <div class="main">
    <b-row>
      <b-col md="6">
        <div class="box_order_form">
          <div class="head text-center">
            <h3><i class="mdi mdi-clock"></i> Program de lucru</h3>
          </div>
          <div v-for="(day, index) in days" :key="index" class="card card-style mx-0">
            <div class="content ">
              <div class="row mb-2 ">
                <h4 class="mb-3">{{day.name}}</h4>

                <div class="row" v-for="(interval, ind) in day[Object.keys(day)[0]]" :key="ind">

                  <div class="col-6 input-style input-style-always-active has-borders has-icon validate-field">
                    <i
                        class="fa font-12 fa-clock"
                    >
                    </i>
                    <b-form-select
                        id="from"
                        v-model="interval.from"
                        :options="hours"
                        size="sm"
                        class="form-control"
                    >
                    </b-form-select>
                    <label for="from" class="color-blue-dark font-13">De la </label>
                  </div>
                  <div class="col-6 input-style input-style-always-active has-borders has-icon validate-field">
                    <i
                        class="fa font-12 fa-clock"
                    >
                    </i>
                    <b-form-select
                        id="to"
                        v-model="interval.to"
                        :options="hours"
                        size="sm"
                        class="form-control"
                    >
                    </b-form-select>
                    <label for="to" class="color-blue-dark font-13">pana la </label>
                  </div>
                  <b-button size="sm" variant="danger" v-if="ind != 0" @click="removeRow(day[Object.keys(day)[0]], ind)">Sterge interval</b-button>
                </div>
                <b-button  @click="addRow(day[Object.keys(day)[0]], index)">Adauga interval nou</b-button>

              </div>
            </div>
          </div>
          <b-button @click="saveOpeningHours" class="p-2 m-2" style="width: 100%;" variant="primary">ACTUALIZEAZA PROGRAMUL DE LUCRU</b-button>

        </div>
      </b-col>
      <b-col md="6">
        <div class="box_order_form">
          <div class="head text-center">
            <h3><i class="mdi mdi-application-settings"></i> Setari</h3>
          </div>
          <div class="main">
            <label class="container_check">Livrare activata
              <input v-model="restaurant.delivery_active" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <b-button @click="saveSettings" class="p-2 m-2" style="width: 100%;" variant="primary">ACTUALIZEAZA SETARILE</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Restaurants from '@/components/restaurantManagerEndpoints/restaurants'
import OpeningHours from '@/components/restaurantManagerEndpoints/openingHours'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  data() {
    return {
      error: false,
      days: [
        { monday: [{"from": "2", "to": "3"}], name: 'Luni'},
        { tuesday: [{"from": "1", "to": "4"}], name: 'Marti'},
        { wednesday: [{"from": "", "to": ""}], name: 'Miercuri'},
        { thursday: [{"from": "", "to": ""}], name: 'Joi'},
        { friday: [{"from": "", "to": ""}], name: 'Vineri'},
        { saturday: [{"from": "", "to": ""}], name: 'Sambata'},
        { sunday: [{"from": "", "to": ""}], name: 'Duminica'},
      ],
      restaurant: {
        delivery_active: true,
        max_delivery_km_range: null,
        delivery_price_extra_km: 0,
        delivery_extra_price_from_km: 0,
        delivery_fixed_price_extra_km: 0
      }
    }
  },
  methods: {
    addRow: function(day, index) {
      day.push({
        from: '',
        to: '',
      })
    },
    removeRow: function(day, index ) {
      day.splice(index, 1);
    },
    getData() {
      Restaurants.get().then(response => {
        this.restaurant = response.data.data
        if(this.restaurant.opening_hours_new) {
          this.days = this.restaurant.opening_hours_new;
        }
      })
    },
    saveOpeningHours() {
      OpeningHours.update(this.days).then(response => {
        if(response.data.success) {
          this.$swal.fire({
            title: 'Program de lucru actualizat',
            text:  'Programul de lucru a fost actualizat cu succes',
            icon: 'success'
          })
        }
      }).catch(() => {
        this.$swal.fire({
          title: 'Atentie',
          text:  'Programul de lucru nu a putut fi actualizat',
          icon: 'error'
        })
      })
    },
    saveSettings() {
      Restaurants.update(this.restaurant.id, this.restaurant).then(response => {
        this.restaurant = response.data.data
        this.$swal('Setari actualizate', 'Setarile au fost actualizat cu success', 'success')
      })
    }
  },
  computed: {
    hours() {
      let returnData = [];
      const tmpObj = {
        value: null,
        text: 'Inchis'
      }
      returnData.push(tmpObj)
      for (let i = 1; i <= 24; i++) {
        let currentHour = i;
        if (i < 10) {
          currentHour = "0" + i;
        }
        const tmpObj = {
          value: currentHour + ':00',
          text: currentHour + ':00',
        }

        returnData.push(tmpObj)
        if (currentHour !== 24) {
          const tmpObj2 = {
            value: currentHour + ':30',
            text: currentHour + ':30',
          }
          returnData.push(tmpObj2)
        }
      }
      return returnData
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style>
ul.dropdown-menu{
  z-index: 100;
  position: absolute;
}
</style>
