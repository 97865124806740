<template>
  <div>
    <b-modal
        id="create-product"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Adaugare produs</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Categorie</label>
              <b-form-select v-model="product.category_id" :options="categoriesOptions" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="product.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Descriere</label>
              <b-form-textarea v-model="product.description" size="sm" class="form-control"></b-form-textarea>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Pret</label>
                  <b-input type="number" v-model="product.price" placeholder="Pret (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status</label>
                  <b-form-select v-model="product.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <label>Pozitie</label>
              <b-input type="text" v-model="product.order_index" placeholder="Pozitia in lista"></b-input>
            </div>
            <div class="form-group">
              <label>Imagine</label>
              <ImageUploadCropper
                  :aspectRatio="1"
                  :defaultImage="null"
                  @cropMode="edit = $event"
                  @fileUpdated="files = $event"
                  @fileRemoved="fileRemoved()"
                  :uploadButtonText="$t('media.uploadImage')"
                  :cropButtonText="$t('media.cropImage')"
                  :removeButtonText="$t('media.removeImage')"
              >
              </ImageUploadCropper>
            </div>
            <div class="form-group">
              <label>Caracteristici</label>
              <v-select
                  v-model="product.features"
                  dir="ltr"
                  multiple
                  label="text"
                  :closeOnSelect="false"
                  :reduce="text => text.value"
                  :options="featureOptions"
              >
              </v-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Se salveaza...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Categories from '@/components/restaurantManagerEndpoints/categories'
import Products from '@/components/restaurantManagerEndpoints/products'
import ProductsFeatures from '@/components/restaurantManagerEndpoints/productsFeatures'
import { bus } from "../../../main";
import ImageUploadCropper from "../../System/Media/ImageUploadCropper";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

export default {
  data() {
    return {
      isBusy: false,
      files: [],
      edit: false,
      showModal: false,
      product: {
        name: '',
        description: '',
        is_active: true,
        category_id: '',
        price: 0,
        features: []
      },
      statuses: [
        { value: true, text: 'Activ' },
        { value: false, text: 'Inactiv' },
      ],
      categories: [],
      features: []
    }
  },
  components: {
    vSelect,
    ImageUploadCropper
  },
  created() {
    this.$root.$refs.RestaurantManagerCreateProductModal = this;
  },
  methods: {
    fileRemoved() {
      this.files = []
    },
    cancel() {
      this.showModal = false
      this.product = {
        name: '',
        description: '',
        is_active: true,
        category_id: null,
        price: 0
      }
      this.files = [];
      this.isBusy = false;
    },
    getCategories() {
      const params = {
        page: 1,
        per_page: 100
      }
      Categories.getAll(params).then(response => {
        this.categories = response.data.data
      })
    },
    getFeatures() {
      const params = {
        page: 1,
        per_page: 100
      }
      ProductsFeatures.getAll(params).then(response => {
        this.features = response.data.data
      })
    },
    save() {
      let storeData = new FormData();
      if (this.files.length) {
        storeData.append("files[]", this.files[0].file);
      }

      Object.entries(this.product).forEach(([field, value]) => {
        storeData.append(field, value);
      });
      this.isBusy = true;
      Products.store(storeData).then(response => {
        bus.$emit('productCreated', response.data.data)
        this.cancel()
      })
    }
  },
  computed: {
    categoriesOptions() {
      const returnData = [{value: '', text: 'Alege categoria'}];
      this.categories.forEach(category => {
        const tmpObj = {
          value: category.id,
          text: category.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    featureOptions() {
      const returnData = []
      this.features.forEach(feature => {
        const tmpObj = {
          value: feature.id,
          text: feature.label + ' - ' + feature.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
  },
  mounted() {
    this.getCategories()
    this.getFeatures()
  }
}
</script>
