<template>
  <div>
    <b-modal
        id="edit-category"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Modificare categorie</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="category.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Descriere</label>
              <b-form-textarea v-model="category.description" size="sm" class="form-control"></b-form-textarea>
            </div>
            <div class="form-group">
              <label>Status</label>
              <b-form-select v-model="category.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="form-group">
              <label>Pozitie</label>
              <b-input type="text" v-model="category.order_index" placeholder="Pozitia in lista"></b-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6">
            <b-button variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Categories from '@/components/restaurantManagerEndpoints/categories'
import { bus } from "../../../main";

export default {
  data() {
    return {
      files: [],
      showModal: false,
      category: {
        name: '',
        description: '',
        is_active: true,
      },
      statuses: [
        { value: true, text: 'Activata' },
        { value: false, text: 'Dezactivata' },
      ],
    }
  },
  methods: {
    cancel() {
      this.showModal = false
    },
    save() {
      Categories.update(this.category.id, this.category).then(() => {
        bus.$emit("categoryUpdated")
        this.cancel()
      })
    }
  },
  mounted() {
    bus.$on('showCategoryEditModal', id => {
      Categories.get(id).then(response => {
        this.category = response.data.data
        this.showModal = true
      })
    })
  }
}
</script>
