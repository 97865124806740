<template>
  <main class="bg_gray" style="padding-top: 150px;">
    <b-container>
      <b-row>
        <b-col md="3">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>MENIU ADMINISTRARE</h3>
            </div>
            <div class="impersonated" v-if="impersonatedUsers">
              <b-row>
                <b-col md="12" class="p-1" v-for="(user, index) in impersonatedUsers" :key="index">
                  <b-button @click="backToUser(user)" block class="w-100" variant="primary"><i class="mdi mdi-exit-run"></i> {{ user.user_data.email }}</b-button>
                </b-col>
              </b-row>
            </div>
            <!-- /head -->
            <div class="main">
              <div>
                <b-nav pills vertical class="text-center">
                  <b-nav-item :active="$route.name === 'restaurant-manager.dashboard'" :to="{ name: 'restaurant-manager.dashboard' }">Dashboard</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.orders.pre'" :to="{ name: 'restaurant-manager.orders.pre' }">Precomenzi [{{this.preOrdersTotal}}]</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.orders.new'" :to="{ name: 'restaurant-manager.orders.new' }">Comenzi noi [{{this.newOrdersTotal}}]</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.orders'" :to="{ name: 'restaurant-manager.orders' }">Istoric Comenzi</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.categories'" :to="{ name: 'restaurant-manager.categories' }">Categorii</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.products'" :to="{ name: 'restaurant-manager.products' }">Produse</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.products.features'" :to="{ name: 'restaurant-manager.products.features' }">Caracteristici Produse</b-nav-item>
                  <b-nav-item :active="$route.name === 'restaurant-manager.settings'" :to="{ name: 'restaurant-manager.settings' }">Setari</b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.dashboard'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>DASHBOARD</h3>
            </div>
            <!-- /head -->
            <Statistics />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.orders.pre'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>Precomenzi</h3>
            </div>
            <PreOrders v-on:childToParent="updateTotalFromChild" />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.orders.new'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>COMENZI NOI</h3>
            </div>
            <NewOrders v-on:childToParent="updateTotalFromChild" />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.orders'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>ISTORIC COMENZI</h3>
            </div>
            <Orders />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.categories'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>CATEGORII</h3>
            </div>
            <Categories />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.products'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>PRODUSE</h3>
            </div>
            <Products />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.products.features'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>Caracteristici produse</h3>
            </div>
            <ProductsFeatures />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'restaurant-manager.settings'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>SETARI</h3>
            </div>
            <Settings />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import RestaurantManagerOrders from '@/components/restaurantManagerEndpoints/orders'
import Statistics from '@/views/RestaurantManager/Statistics'
import Orders from '@/views/RestaurantManager/Orders/Orders'
import NewOrders from '@/views/RestaurantManager/Orders/NewOrders'
import PreOrders from '@/views/RestaurantManager/Orders/PreOrders'
import Categories from '@/views/RestaurantManager/Categories/Categories'
import Products from '@/views/RestaurantManager/Products/Products'
import ProductsFeatures from '@/views/RestaurantManager/ProductsFeatures/ProductsFeatures'
import Settings from '@/views/RestaurantManager/Settings/Index'
import { bus } from "../../main";

export default {
  data() {
      return {
        error: false,
        preOrdersTotal: 0,
        newOrdersTotal: 0,
        paging: {
          page: 1,
          per_page: 90,
          total: 10
        },
      }
  },
  computed: {
    impersonatedUsers() {
      let impersonatedUsers = localStorage.getItem('impersonated');
      if (impersonatedUsers) {
        return JSON.parse(impersonatedUsers)
      }
      return false;
    },
  },
  components: {
    Statistics,
    Orders,
    NewOrders,
    PreOrders,
    Categories,
    Products,
    ProductsFeatures,
    Settings
  },
  methods: {
    backToUser(user) {
      localStorage.setItem('token', user.token)
      localStorage.setItem('userInfo', JSON.stringify(user.user_data))
      if (user.user_data.role === 'restaurant-manager') {
        this.$router.push({ name: 'restaurant-manager.dashboard' })
      } else if (user.user_data.role === 'city-manager') {
        let impersonatedUsers = JSON.parse(localStorage.getItem('impersonated'));
        let newImpersonated = [];
        impersonatedUsers.forEach(user => {
          if (user.user_data.role !== 'city-manager') {
            newImpersonated.push(user);
          }
        })
        localStorage.setItem('impersonated', JSON.stringify(newImpersonated))
        this.$router.push({name: 'city-manager.dashboard'})
      } else if (user.user_data.role === 'driver') {
        this.$router.push({ name: 'driver.dashboard' })
      } else if (user.user_data.role === 'customer') {
        this.$router.push({ name: 'customer.account' })
      } else if (user.user_data.role === 'administrator') {
        this.$router.push({ name: 'admin.dashboard' })
        localStorage.removeItem('impersonated')
      }
      bus.$emit('loggedIn', user.user_data);
    },
    updateTotalFromChild(type,val){
      this[type] = val;
    },
    updateTotalOrders() {
      const filterPreOrders = {
        status: [0,1,2],
        preorder_date: 'preorder'
      }
      const filtersNewOrders = {
        status: [0,1,2],
        preorder_date: 'order'
      }
      RestaurantManagerOrders.getAll(this.paging, filterPreOrders).then(response => {
        this.preOrdersTotal = response.data.meta.total;
      })
      RestaurantManagerOrders.getAll(this.paging, filtersNewOrders).then(response => {
        this.newOrdersTotal = response.data.meta.total;
      })
    },
  },
  mounted() {
    this.updateTotalOrders()
  }
}
</script>