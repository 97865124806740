<template>
  <div>
    <b-modal
        id="create-order"
        size="xl"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Adaugare comanda</h3>
          </b-col>
        </b-row>
        <b-modal
        id="modal-options-product"
        v-model="showProductFeatures"
        hide-header
        footer-bg-variant="white"
        class="zoom-anim-dialog"
    >
      <div class="content">
        <h5>{{ selectedProduct.name }}</h5>
        <h6>{{ selectedProduct.description }}</h6>
        <p>Pret: {{ toCurrency(selectedProductTotalPrice) }}</p>
        <hr>
        <div class="product-options-wrapper">
          <div class="product-feature"
              v-for="(feature, index) in selectedProduct.features"
              :key="index"
          >
          <!-- RADIOS -->

           <b-form-group class="feature-label"
            label-size="sm"
           :description="checkRequiredFeature(feature)"
            :label="feature.label" v-slot="{ ariaDescribedby }" v-if="feature.max === 1">
            <b-form-radio
              v-for="(option, optionIndex) in feature.options"
              v-bind:class="{ lastOption: optionIndex+1 === feature.options.length }"
              @change="featureRadioChanged(feature.id, option.name)"
              :key="optionIndex"
              v-model="option.checked"
              :value="true"
              :aria-describedby="ariaDescribedby"
              :name="feature.name.replace(' ', '')"
            >
              {{ option.label }}<span> +{{ toCurrency(option.price) }}</span>
            </b-form-radio>
          </b-form-group>

            <!-- CHECKBOXES OR MULTIPLE -->

            <b-form-group class="feature-label"
            :description="checkRequiredFeature(feature)"
             :label="feature.label" v-slot="{ ariaDescribedby }" v-if="feature.max >= 2">
              <div class="form-checkboxes" v-for="(option, optionIndex) in feature.options"
                  :key="optionIndex" v-bind:class="{ lastOption: optionIndex+1 === feature.options.length }">
                   <b-form-checkbox
                    :aria-describedby="ariaDescribedby"
                    :id="option.id"
                    v-model="option.checked"
                    :name="feature.name.replace(' ', '')"
                    v-if="!option.multiple"
                    class="checkboxed-btn"

                  >
                    {{ option.label }}<span> +{{ toCurrency(option.price) }}</span>
                  </b-form-checkbox>

                <div class="form-group option-multiple" v-if="option.multiple">
                  <div class="option-label">{{ option.label }} <span> +{{ toCurrency(option.price) }}</span></div>
                  <div class="wrapper-qty">
                      <b-button squared @click="changeQtyOptionFeature(feature, option, -1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-minus" /></b-button>
                      <b-button squared disabled variant="outline-secondary" class="qty">{{option.qty}}</b-button>
                      <b-button squared @click="changeQtyOptionFeature(feature, option, 1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-plus" /></b-button>
                  </div>
                  <b-form-input v-show="false" class="qty-feature" :min="0" :max="option.max" type="number" v-model="option.qty" />
                </div>
              </div>
            </b-form-group>

          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="modal-footer-custom">
          <b-button variant="outline-secondary" @click="showProductFeatures = false">Inchide</b-button>
          <b-button variant="outline-success" @click="addProductFeaturesToBasket">Adauga in cos</b-button>
        </div>
      </template>
        </b-modal>
        <b-row>
          <b-col md="12">
            <b-tabs
                ref="categoriesTabsHeader"
                fill
                content-class="col-md-12 p-0"
                pills
                align="center"
                nav-wrapper-class="col-md-12 card p-0 mb-1 bg-light border-bottom categoriesTabs"
                class="m-0"
                card
            >
              <b-tab class="p-0" v-for="(products, category) in restaurant.categories" :key="category">
                <template #title>
                  <span class="font-weight-bold">{{ category }}</span>
                </template>
                <div class="list_menu" id="list_menu">
                  <section>
                    <div class="table_wrapper">
                      <table class="table cart-list menu-gallery">
                        <thead>
                        <tr>
                          <th>Produs</th>
                          <th style="width: 75px;">Pret</th>
                          <th style="width: 25px;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in products" :key="index">
                          <td class="d-md-flex align-items-center productsFirstTDMaxWidth">
                            <figure>
                              <b-img :src="item.image_url"></b-img>
                            </figure>
                            <div class="flex-md-column">
                              <h4>{{ item.name }}</h4>
                              <div style="white-space: pre-line;">
                                {{ item.description }}
                              </div>
                            </div>
                          </td>
                          <td>
                            <strong>{{ toCurrency(item.price) }}</strong>
                          </td>
                          <td class="options">
                            <b-link @click="addToBasket(item)"><i class="icon_plus_alt2"></i></b-link>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col md="12">
            <div class="box_order mb-2">
              <div class="head">
                <h3>Sumar Comanda</h3>
                <a href="#0" class="close_panel_mobile"><i class="icon_close"></i></a>
              </div>
              <!-- /head -->
              <div class="main">
                <div class="wrapper-cart">
                  <div class="product-cart" v-for="(item, index) in basket" :key="index">
                    <div class="product-details">
                      <div><b-link class="item-name" @click="removeFromBasket(item, index)"><span>{{item.qty}} x {{ item.name }}</span><i class="mdi mdi-delete" /></b-link></div>
                      <div v-for="(feature, ind) in item.features" :key="ind">
                          <div v-for="(opt, ix) in feature.options" :key="ix">
                            <div v-if="opt.checked">
                              <div v-if="!opt.multiple">{{1 * item.qty}} x {{opt.label}} <span class="price">{{toCurrency(opt.price)}}</span></div>
                              <div v-else-if="opt.multiple">{{opt.qty * item.qty}} x {{opt.label}} <span class="price">{{toCurrency(opt.price * opt.qty)}}</span></div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="qty-total">
                      <div class="wrapper-qty">
                          <b-button squared @click="changeQtyBasket(index, -1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-minus" /></b-button>
                          <b-button squared disabled variant="outline-secondary" class="qty">{{item.qty}}</b-button>
                          <b-button squared @click="changeQtyBasket(index, 1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-plus" /></b-button>
                      </div>
                      <div class="total-price-item"><span>{{ toCurrency(item.price) }}</span></div>
                    </div>
                  </div>
                </div>
                <ul class="clearfix">
                  <li>Subtotal<span>{{ toCurrency(basketTotals.subtotal) }}</span></li>
                  <li>Taxa livrare<span>{{ toCurrency(restaurant.delivery_tax) }}</span></li>
                  <li class="total">Total<span>{{ toCurrency(basketTotals.total) }}</span></li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <div class="box_order_form mt-2">
              <div class="head">
                <div class="title">
                  <h3>Informatii Comanda</h3>
                </div>
              </div>
              <!-- /head -->
              <div class="main">
                <div class="form-group">
                  <label>Telefon</label>
                  <b-input @keyup="searchOrder" type="text" maxlength="10" v-model="orderDetails.phone" placeholder="Telefon (obligatoriu)"></b-input>
                </div>
                <div>
                  <b-row>
                    <b-col class="card" md="4" v-for="(entry, index) in searchOrderResult" :key="index">
                      {{ entry.order_details.firstName }} {{ entry.order_details.lastName }} <br>
                      {{ entry.order_details.street }} {{ entry.order_details.city }} <br>
                      {{ entry.order_details.phone }}
                      <b-button variant="primary" @click="selectFoundClientData(entry.order_details)">PREIA DATELE CLIENTUL GASIT</b-button>
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <label>Prenume</label>
                  <b-input type="text" v-model="orderDetails.firstName" placeholder="Prenume (obligatoriu)"></b-input>
                </div>
                <div class="form-group">
                  <label>Nume</label>
                  <b-input type="text" v-model="orderDetails.lastName" placeholder="Nume (obligatoriu)"></b-input>
                </div>
                <div class="form-group">
                  <label>Strada, nr</label>
                  <gmap-autocomplete
                      ref="address_input_autocomplete"
                      class="form-control"
                      placeholder="Strada si nr (obligatoriu)"
                      @place_changed="setLocation"
                      @keyup="locationSelected = false"
                  >
                  </gmap-autocomplete>
                </div>
                <div class="form-group" v-if="locationSelected">
                  <label>Bloc, Apartament, etc.</label>
                  <b-input type="text" v-model="orderDetails.address_extra"></b-input>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Oras</label>
                      <b-input type="text" v-model="orderDetails.city" placeholder="Oras (obligatoriu)"></b-input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Cod Postal</label>
                      <b-input type="text" v-model="orderDetails.zip" placeholder="Nu este obligatoriu"></b-input>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Mentiuni comanda</label>
                  <b-textarea v-model="orderDetails.customer_notes" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col sm="12" md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">COMANDA ACUM</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">COMANDA SE SALVEAZA...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { bus } from "../../../main";
import Restaurant from '@/components/restaurantManagerEndpoints/restaurants'
import Orders from '@/components/restaurantManagerEndpoints/orders'
export default {
  data() {
    return {
      locationSelected: false,
      isBusy: false,
      showModal: false,
      basket: [],
      restaurant: {},
      orderDetails: {
         firstName: '',
         lastName: '',
         street: '',
         address_extra: '',
         zip: '',
         city: '',
         customer_notes: '',
      },
      errorFields: [],
      extra_delivery_confirmed: false,
      searchOrderResult: [],
      extra_delivery_confirmed: false,
      sms_code: null,
      verificationMailSent: false,
      userCheckTimeout: null,
      paymentMode: 'cash',
      stripe: null,
      showPaymentDetailsModal: false,
      payElements: null,
      paymentConfirmed: false,
      paymentIntentId: null,
      preorderTime: "",
      currentProductModal: '',
      showProductImage: false,
      footerBgVariant: 'dark',
      showProductFeatures: false,
      selectedProduct: false,
      selectedProductInitialState: {},
      csyToken: process.env.VUE_APP_CSY_LOGO_PATH,
      numarTel: '',
      formattedPhone: '',
      numberInvalid: true
    }
  },
  components: {

  },
  created() {
    this.$root.$refs.RestaurantManagerCreateOrderModal = this;
  },
  methods: {
    setLocation(location) {
      let street = false
      let streetNo = false
      let city = false
      location.address_components.forEach(entry => {
        if (entry.types.includes('route') && !street) {
          street = entry.long_name
        }
        if (entry.types.includes('street_number') && !streetNo) {
          streetNo = entry.long_name
        }
        if (entry.types.includes('locality') && !city) {
          city = entry.long_name
        }
      })

      this.orderDetails.city = city
      this.orderDetails.street = street
      if (street) {
        this.$refs.address_input_autocomplete.$el.value = street
      }
      if (streetNo) {
        this.$refs.address_input_autocomplete.$el.value += ' ' + streetNo
      }
      this.locationSelected = true
    },
    getRestaurant() {
      Restaurant.get().then(response => {
        this.restaurant = response.data.data
        this.orderDetails.city = this.restaurant.city
      })
    },
    addToBasket(item) {
      if (item.features && item.features.length) {
        this.showProductFeatures = true
        let currentProduct = JSON.parse(JSON.stringify(item));
        this.selectedProduct = currentProduct
        this.selectedProductInitialState = { ...item }
        return
      }
      item.qty = 1;
      let itemToPush = JSON.parse(JSON.stringify(item));
      let itemFound = this.basket.find(x => x.id === itemToPush.id);
      if (itemFound) {
        itemFound.qty += 1;
        itemFound.initPrice = item.price;
        itemFound.price = itemFound.qty * itemFound.initPrice;
      } else {
        this.basket.push(itemToPush);
      }
      this.$toastr.s('1 x ' + item.name + ' a fost adaugat in comanda');
    },
    checkFeatureRequired(item) {
      let featuresRequired = item.features.filter(x => x.required === true);

      return this.checkMaxAndMinOptions(featuresRequired, 'required');
    },
    checkMaxOptionsOptional(item) {
      let featuresOptional = item.features.filter(x => x.required === false);
      console.log('featuresOptional', featuresOptional);
      return this.checkMaxAndMinOptions(featuresOptional, 'optional');
    },

    checkMaxAndMinOptions(features, type) {
      let sumCheckedCount = 0;
      let sumMinOptions = 0;
      let sumMaxOptions = 0;
      for (let i = 0; i < features.length; i++) {
        let checkedCountOptions = this.countCheckedOptions(features[i].options);
        sumMinOptions += features[i].min;
        sumMaxOptions += features[i].max;
        sumCheckedCount += checkedCountOptions;
        if (checkedCountOptions < features[i].min && type === 'required') {
          this.$toastr.Add({
            name: features[i].label, // this is give you ability to use removeByName method
            msg: 'Obligatoriu ' + features[i].label, // Toast Message
            timeout: 5000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
            type: "error", // Toast type,
            preventDuplicates: true, //Default is false,
          });
        }
        if (checkedCountOptions > features[i].max) {
          this.$toastr.Add({
            name: features[i].label, // this is give you ability to use removeByName method
            msg: `Alege maxim ${features[i].max} optiuni pentru ${features[i].label}`, // Toast Message
            timeout: 5000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
            type: "error", // Toast type,
            preventDuplicates: true, //Default is false,
          });
        }
      }

      if (sumCheckedCount > sumMaxOptions) {
        return false;
      }

      if (sumCheckedCount < sumMinOptions && type == 'required') {
        return false;
      }
      return true
    },
    addProductFeaturesToBasket() {
      this.selectedProduct.price = this.selectedProductTotalPrice;
      this.selectedProduct.qty = 1;
      let itemToPush = JSON.parse(JSON.stringify(this.selectedProduct));
      let valid = this.checkFeatureRequired(itemToPush);
      if (!valid) {
        return;
      }
      let validMax = this.checkMaxOptionsOptional(itemToPush);
      if (!validMax) {
        return;
      }
      this.showProductFeatures = false;
      let itemFoundFeature = this.basket.filter(x => x.id === itemToPush.id);

      let findSameFeatures = true;
      if (itemFoundFeature.length) {
        for (let i = 0; i < itemFoundFeature.length; i++) {
          if (isEqual(itemFoundFeature[i].features,itemToPush.features)) {
            itemFoundFeature[i].qty += 1;
            itemFoundFeature[i].initPrice = itemToPush.price;
            itemFoundFeature[i].price = itemFoundFeature[i].qty * itemFoundFeature[i].initPrice;
            findSameFeatures = false;
          }
        }
      }

      if (findSameFeatures) {
        this.basket.push(itemToPush);
      }
    },
    removeFromBasket(item, index) {
      this.selectedProduct = false
      this.basket.splice(index, 1);
    },
    cancel() {
      this.showModal = false
      this.order = {
        name: '',
        description: '',
        is_active: true
      }
      this.orderDetails = {
        firstName: '',
        lastName: '',
        street: '',
        zip: '',
        city: this.restaurant.city,
      };
      this.isBusy = false;
      this.extra_delivery_confirmed = false;
    },
    save() {
      this.orderDetails.street = this.$refs.address_input_autocomplete.$el.value;
      const orderData = {
        products: this.basket,
        order_details: this.orderDetails,
        extra_delivery_confirmed: this.extra_delivery_confirmed,
        created_by_restaurant: true
      }
      this.isBusy = true;
      Orders.store(orderData).then(response => {
        this.basket = []
        this.extra_delivery_confirmed = false;
        bus.$emit("restaurant_new_order")
        this.cancel()
      }).catch(error => {
        this.isBusy = false;
        if (error.response.data.type === 'order_details') {
          this.$swal('Atentie', 'Pentru a putea comanda va rugam sa completati campurile obligatorii', 'warning')
        } else if (error.response.data.type === 'min_order_amount') {
          this.$swal('Comanda minima: ' + this.toCurrency(this.restaurant.min_order_amount_value) + ' (exclus livrare)', 'Acest restaurant accepta doar comenzi cu valoarea produselor de peste ' + this.toCurrency(this.restaurant.min_order_amount_value), 'warning')
        } else if(error.response.data.type === 'max_delivery_range_reached') {
          this.$swal('Nu se poate livra la aceasta adresa', 'Adresa unde doriti livrarea produselor este inafara ariei de acoperire a acestui restaurant', 'warning')
        } else if(error.response.data.type === 'address_not_found') {
          this.$swal('Adresa nu poate fi gasita', 'Adresa introdusa nu poate fi gasita', 'warning')
        } else if(error.response.data.type === 'max_delivery_range_reached') {
          this.$swal('Nu se poate livra la aceasta adresa', 'Adresa unde doriti livrarea produselor este inafara ariei de acoperire a acestui restaurant', 'warning')
        } else if(error.response.data.type === 'delivery_extra_price') {
          const message = "Adresa unde doriti livrarea produselor are un cost suplimentar de "
              + this.toCurrency(error.response.data.extra_delivery_tax) +
              "<br>Totalul comenzii va fi in valoare de " +
              this.toCurrency(parseFloat(this.basketTotals.total) + parseFloat(error.response.data.extra_delivery_tax));

          this.$swal({
            title: 'Atentie !!!',
            html: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'DA, CONFIRM COMANDA',
            cancelButtonText: 'NU, RENUNT',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.extra_delivery_confirmed = true;
              this.$nextTick().then(() => {
                this.save();
              })
            }
          })
        }
      })
    },
    searchOrder() {
      if (this.orderDetails.phone === '' || this.orderDetails.phone.length < 7) {
        this.searchOrderResult = []
        return;
      }
      const params = {
        page: 1,
        per_page: 1
      }
      const filters = {
        phone: this.orderDetails.phone
      }
      Orders.getAll(params, filters).then(response => {
        this.searchOrderResult = response.data.data;
      }).catch(() => {
        this.searchOrderResult = []
      })
    },
    selectFoundClientData(orderDetails) {
      this.$refs.address_input_autocomplete.$el.value = orderDetails.street
      this.orderDetails.phone = orderDetails.phone
      this.orderDetails.firstName = orderDetails.firstName
      this.orderDetails.lastName = orderDetails.lastName
      this.orderDetails.street = orderDetails.street
      this.orderDetails.zip = orderDetails.zip
      this.searchOrderResult = []
    },
    featureRadioChanged(featureId, selectedName) {
      let featureData = this.selectedProduct.features.find(x => x.id === featureId);
      featureData.options.forEach(entry => {
        if (entry.name !== selectedName) {
          entry.checked = false
        } else {
          entry.checked = true
        }
      })
    },
    changeQtyOptionFeature(feature, option, val) {
      let featureData = this.selectedProduct.features.find(x => x.id === feature.id);
      featureData.options.forEach(entry => {
        if (entry.name === option.name) {
          let newVal = parseInt(option.qty) + val;
          entry.checked = true;
          if (newVal < 1) {
            entry.qty = 0;
            entry.checked = false;
          } else if (newVal > option.max) {
            entry.qty = option.max;
          } else {
            entry.qty = parseInt(option.qty) + val;
          }
        }
      })
    },
    changeQtyBasket(index, val) {
      let newQty = this.basket[index].qty + val;
      let initialPrice = this.basket[index].price / this.basket[index].qty;
      if (newQty < 1) {
        return;
      }
      if (val === -1) {
        this.basket[index].price = this.basket[index].price - initialPrice;
      } else {
        this.basket[index].price = this.basket[index].price + initialPrice;
      }
      this.basket[index].qty = newQty;
    },
    checkRequiredFeature(feature) {
      let countOptionsChecked = this.countCheckedOptions(feature.options);
      if (!feature.required) {
        return `Optional Max: ${feature.max} - Selectat: ${countOptionsChecked}`;
      }
      return feature.required ? `Obligatoriu Min: ${feature.min} / Max: ${feature.max} - Selectat: ${countOptionsChecked}`: '';
    },
    countCheckedOptions(options) {
      let optionsChecked = options.filter(x => x.checked === true);
      return optionsChecked.length;
    }
  },
  mounted() {
    this.getRestaurant()
  },
  computed: {
    basketTotals() {
      let returnData = {
        subtotal: 0,
        delivery: 0,
        total: 0
      }
      if(this.basket.length === 0) {
        return returnData
      }
      this.basket.forEach(item => {
        returnData.subtotal = returnData.subtotal + item.price
      })
      returnData.delivery = this.restaurant.delivery_tax
      returnData.total = returnData.subtotal + returnData.delivery
      return returnData
    },
    selectedProductTotalPrice() {
      if (!this.selectedProduct) {
        return 0
      }
      let extraPrices = []
      this.selectedProduct.features.forEach((entry, i) => {
        entry.options.forEach((option, ii) => {
          if (option.multiple) {
            if (option.qty * option.price > 0) {
              extraPrices.push(parseFloat(option.qty) * parseFloat(option.price))
            }
          } else {
            if (option.checked) {
              extraPrices.push(parseFloat(option.price))
            }
          }
        })
      })
      const extraPricesTotal = parseFloat(extraPrices.reduce((a, b) => a + b, 0));
      const initialProductPrice = parseFloat(this.selectedProductInitialState.price)
      return initialProductPrice + extraPricesTotal
    }
  }
}
</script>

<style>
.pac-container {
  background-color: #FFF;
  z-index: 9999 !important;
  position: fixed;
  display: inline-block;
  float: left;
}
.modal{
  z-index: 20 !important;
}
.modal-backdrop{
  z-index: 10 !important;
}
.wrapper-qty {
  display: flex;
  justify-content: space-around;
}
.wrapper-qty .btn.btn-outline-secondary {
  border: none;
}
.wrapper-qty .qty {
  width: 42px;
  opacity: 1;
}
.option-label {
  font-size: 1rem;
}
.option-label span, .checkboxed-btn span, .custom-radio span {
  color: #5273C0;
}
.feature-label {
  margin-bottom: 0px;
}
.feature-label .col-form-label {
  font-weight: bold;
  font-size: 1.5rem;
}
.form-checkboxes {
  margin-bottom: 1rem;
}
.custom-control label {
  margin-bottom: 0;
}
.checkboxed-btn {
  font-size: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.checkboxed-btn .custom-control-label {
  font-weight: normal;
}

.custom-checkbox .custom-control-input {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  position: relative;
  border-radius: 50px;
}

.custom-radio {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;
}
.custom-radio .custom-control-label {
  font-size: 1rem;
}
.custom-radio .custom-control-input {
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 10px;
}

.btn-outline-secondary,
.btn-outline-secondary.disabled {
  color: #5273C0;
  border-color: #5273C0;
}
.btn-outline-secondary:hover, .btn-outline-secondary.active {
  background-color: #5273C0;
  border-color: #5273C0;
}
.product-feature {
  width: 100%;
}
.product-options-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}
.option-multiple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-device-width: 480px) {
  .productsFirstTDMaxWidth {
    max-width: 100px !important;
  }
  .categoriesTabs {
    position: fixed;
    top: 91px;
    left: 0;
    z-index: 5;
  }
}
.pac-container {
  background-color: #FFF;
  z-index: 9999 !important;
  position: fixed;
  display: inline-block;
  float: left;
}
.modal{
  z-index: 20 !important;
}
.modal-backdrop{
  z-index: 10 !important;
}
#text-promo {
  left: 0px;
  top: 15px;
  padding: 5px 10px;
  background: red;
  color: white;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
}
.nav-pills .nav-link, .alert, .swal2-popup, .swal2-styled {
  border-radius: 0!important;
}
#image-product {
  cursor: pointer;
}
.modal-footer-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#modal-image-product .modal-dialog,
#modal-options-product .modal-dialog {
  margin-top: 50px;
}
.product-details {
  width: 100%;
}
.product-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    margin: 10px 0px;
    border-bottom: 1px solid #5273C0;
}
.item-name {
  font-size: 1rem;
  color: #5273C0;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.qty-total {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}
.total-price-item {
  margin-left: 15px;
}
.total-price-item span {
  color: #5273C0;
  font-weight: bold;
}
#modal-image-product .modal-dialog {
  margin-top: 50px;
}
.lastOption {
  margin-bottom: 0px;
}
</style>